import { useRef, useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import ResizeObserver from 'resize-observer-polyfill';

let paramKeys = { 
                    s: 'queryString', 
                    sz: 'pageSize', 
                    i: 'fromIndex', 
                    sort: 'sortTerm', 
                    dir: 'sortDir', 
                    sortlab: 'sortSetting', 
                    subq: 'subquery',
                    tab: 'activeTab' }

const JDCRP_RANGES = [
    { to: 1911 },
    { from: 1911, to: 1929 },
    { from: 1930, to: 1934 },
    { from: 1935, to: 1939 },
    { from: 1940, to: 1944 },
    { from: 1945, to: 1949 },
    { from: 1950, to: 1959 },
    { from: 1960 }
]

const FALLBACK_IMAGE_SIZES = { XLARGE: 600, LARGE: 240, SMALL: 80 }

const getFallbackImageUrl = (result,size) => {
    switch (true) {
    case result._source.type === 'library':
      return `/assets/svg/Icon_${size}_books.svg`

    case result._source.type === 'personal':
      return `/assets/svg/Icon_${size}_belongings.svg`

    case result._source.type === 'materials':
      return `/assets/svg/Icon_${size}_materials.svg`

    case result._source.type === 'art':
      return `/assets/svg/Icon_${size}_art.svg`

    case result._source.type === 'exhibition':
      return `/assets/svg/Icon_${size}_exhibition.svg`

    case result._source.type === 'actor':
      return `/assets/svg/Icon_${size}_peopleorgs.svg`

    case Array.isArray(result._source.type) && result._source.type.includes('archive'):
      return `/assets/svg/Icon_${size}_archives.svg`

    default: 
      return "/assets/images/no-img-thumb-242.png"
    }

}

const getImageAltText = (result) => {
    switch (true) {
    case result._source.type === 'library':
      return `work in a library`

    case result._source.type === 'personal':
      return `personal object`

    case result._source.type === 'materials':
      return `artist material`

    case result._source.type === 'art':
      return `artwork`

    case result._source.type === 'exhibition':
      return `exhibition`

    case result._source.type === 'actor':
      return `people or organization`

    case Array.isArray(result._source.type) && result._source.type.includes('archive'):
      return `archival object`

    default:
        return ""
    }
}
const stateToParams = (state) => {
    /*
    Takes state for our search-fed components and turns it into URL search params so we can bookmark / permalink
    */
    const params = new URLSearchParams();

    for (let [key,value] of Object.entries(state)) {
        // console.log(key,value)
        if (value === undefined) {
            continue
        }

        if (key === "filters" ) {
            if (Object.keys(value).length === 0 ) {
                continue
            }

            for (let [filterKey,filterValue] of Object.entries(value)) {
                params.set(`f.${filterKey}`,filterValue)
            }
        } else {
            let keyForParam = Object.entries(paramKeys).reduce((acc,[paramKey,paramValue]) => { return (paramValue === key) ? paramKey : acc }, false)
            if (keyForParam) {
                params.set(keyForParam,value)
            }
        }
    }

    return params.toString();
}

const paramsToState = (urlSearch) => {
    /*
    Parses the state encoded in the URL search paramters into a usable state variable for instantiation         
    */

    let params = new URLSearchParams(urlSearch);
    const state = {}
    const filters = {}

    // Iterate params, processing "f.*" params as filter values
    for (let [key,value] of params.entries()) {

        if (key.startsWith('f.')) {

            let filterKey = key.substring(2)
            switch(filterKey) {
                case 'date_range':
                    let [start,end,_] = value.split(',')
                    filters[filterKey] = [start,end]
                    break
                case 'images':
                    if (value === "true" ) {
                        filters[filterKey] = true
                    } else if ( value === "false" ) {
                        filters[filterKey] = false
                    }
                    break
                default:
                    filters[filterKey] = value
                    break
            }
        } else {
            let keyForState = paramKeys[key]
            switch (keyForState) {
                case 'fromIndex':
                case 'pageSize':
                    state[keyForState] = Number(value);
                    break
                default:
                    state[keyForState] = value                    
            }
        }

    }

    state.filters = filters
    // console.log(state)
    return state

}

function useElasticSearch(endpoint,query) {
    /*

    `endpoint`: str, an ElasticSearch endpoint
    `query`: str | Object | Array, an ElasticSearch request

    Requests query from endpoint
    If `body` is a string, passes the result directly to fetch({body:}), otherwise serializes as JSON.

    */
    var endpointUrl = new URL(endpoint);
    
    const username = endpointUrl.username;
    const password = endpointUrl.password;
    const authHeader = 'Basic ' + btoa(`${username}:${password}`);

    const headers = new Headers({"Content-Type": "application/json"});
    var credentials = false;

    var searchEndpoint = endpointUrl.href;
    if ( username !== "" && password !== "" ) {
        // FIXME: There should be a nicer way to remove the user / pass..    
        searchEndpoint = endpointUrl.href.replace(`${username}:${password}`,'');

        headers.append("Authorization", JSON.stringify(authHeader));
        credentials = true;
    }

    const [data, setData] = useState(null);

    async function retrieve(url,body) {
        let queryUrl = new URL(url)

        // TODO: Add parameters -- source_content_type='application/json'&source=(json_stringified_query)
        const searchParams = new URLSearchParams({ source_content_type: 'application/json',
                                                 source: ( typeof body === 'string' ) ? body : JSON.stringify(body) })
        queryUrl.search = `?${searchParams.toString()}`

        try {
            const response = await fetch(queryUrl.href, { method: 'GET', 
                                                credentials: credentials ? "include" : "same-origin",
                                                mode: 'cors',
                                                headers: headers });        
            const json = await response.json();
            setData(json);
    
        } catch(err) {
            alert(`We did not get a proper response from the endpoint! ${err}`)
        }

    }

    useEffect(() => {
        if (query !== null) {
            retrieve(searchEndpoint,query);
        }
    },[query]);

    return data;
}

function termAggregation(field,filters) {
    /* Just returns an elasticsearch aggregation query node 
    */
    const aggNode = { terms: 
                        { 
                            size:200, 
                            field: `${field}`
                        },
                        aggs: {
                            active: {
                                filter: searchFilterToElasticFilterSyntax(filters)
                            }
                        }
                    };

    return aggNode;

}

function compositeTermAggregation(field,filters,label=true) {
    /* Just returns an elasticsearch aggregation query node 
        FIXME: No need for this to be a composite anymore as it creates skew across same-field properties
    */
    const aggNode = { composite: 
                        { size:200, sources: [ 
                            { label: { terms: { field: `${field}${ label ? '.label' : '' }` } } }, 
                            ] 
                        },
                        aggs: {
                            active: { filter: searchFilterToElasticFilterSyntax(filters) }
                        }
                    };

    return aggNode;

}

function dateRangeAggregation(field,filters,ranges=JDCRP_RANGES,format="yyyy") {
    /*
    See https://www.elastic.co/guide/en/elasticsearch/reference/current/search-aggregations-bucket-daterange-aggregation.html#_keyed_response

    `field`, String: The name of a field in the elasticsearch mapping, can take path strings ala "timespan.date_begin"
    `ranges`, Array<Object<String, String>>: An array of range nodes with "to" and/or "from" keys
    `format`, String: An elasticsearch date format string
    */
    const aggNode = {
        date_range: {
            field: field,
            format: format,
            ranges: ranges,
            keyed: true
        },
        aggs: {
            active: { filter: searchFilterToElasticFilterSyntax(filters) }
        }
    }

    return aggNode;
}

function searchFacetAggregations(filters) {
    /*
    Returns an aggregation node for an ElasticSearch query, with the filters applied to a sub-aggregation for in-filtered-set counts 

    The twist is that to get proper counting in facet filters each aggregation needs to express the filter conditions on each aggregation
    */
        return {
                entity_type: compositeTermAggregation('display_type',filters,false),
                classification: compositeTermAggregation('classified_as',filters),
                creation_date_range: dateRangeAggregation('creation_date.date_begin',filters),
                active_date_range: dateRangeAggregation('active.date_begin',filters),
                life_date_range: dateRangeAggregation('birth.date_begin',filters),
                timespan_date_range: dateRangeAggregation('timespan.date_first',filters),
                country: compositeTermAggregation('related.location.country',filters,false),
                city: compositeTermAggregation('related.location.city',filters,false),

            }
}

function searchFilterToElasticFilterSyntax(filters) {

    /* Filters are used across the query:
        - To create sub-aggregations on facets for cleaner filter facet display logic 
        - In a post_filter block to filter the actual results for paging via from / size
      
      So this just returns an appropriate `bool` query with each filter term as a `must` constraint  
    */

    let result = { bool: { must: [] } }

    for (const filter in filters) {
        switch (filter) {
            case 'related': 
                result.bool.must.push( { term: {"related.link": filters[filter] } } )
                break;
            case 'classification':
                result.bool.must.push({ term: { "classified_as.label": filters[filter] } }) 
                break;
            case 'entity_type':
                if ( filters[filter] === 'Event' ) {
                    result.bool.must.push({ term: { display_type: 'TimelineEvent' }})
                } else {
                    result.bool.must.push({ term: { display_type: filters[filter] }})
                }

                break;
            case 'date_range':
                let beginYear = filters[filter][0];
                let endYear = filters[filter][1];

                let shouldClause = []

                let rangeClause = {}

                if (beginYear) { 
                    rangeClause['gte'] = Number(beginYear)
                    rangeClause['format'] = 'yyyy'
                }

                if (endYear) { 
                    rangeClause['lte'] = Number(endYear)
                    rangeClause['format'] = 'yyyy'
                }

                shouldClause.push( { range: { 'creation_date.date_begin': rangeClause } } ); 
                shouldClause.push( { range: { 'active.date_begin': rangeClause } } )
                shouldClause.push( { range: { 'birth.date_begin': rangeClause } } )
                shouldClause.push( { range: { 'timespan.date_first': rangeClause } } )

                result.bool.should = shouldClause

                break;
        }
    }

    return result

}

function dateRangeInputFormatter(bucket) {

    return [ bucket.key.from_as_string , bucket.key.to_as_string ]
}

function dateRangeBucketFormatter(buckets) {
    return Object.entries(buckets).map( ([key,bucketNode]) => { 
        return { key: { label: key.replace('*-',"Before ").replace("-*"," - Present"), value: bucketNode.doc_count, to_as_string: bucketNode.to_as_string, from_as_string: bucketNode.from_as_string }, active: bucketNode.active }
    })
}

function yearsToDecades(buckets) {
    /* Converts an array of year histogram buckets into decades */

    var decades = {};
    for ( let bucket of buckets ) {
        let decade = Math.floor( Number(bucket.key_as_string) / 10 ) * 10;
        let decCount = ( decades[decade] ) ? decades[decade] : 0;

        decades[decade] = decCount + Number(bucket.active.doc_count)
    }

    var result = [];
    for (const [key,value] of Object.entries(decades)) {
        result.push( { key: { label: `${key}s`, value: key }, active: { doc_count: value } } )
    }
    return result;
}

function decadeToYears(bucket) {
    const firstYear = Number(bucket.key.value);
    const lastYear = firstYear + 9;

    return [ firstYear, lastYear ]
}

function searchContextToQuery(queryString,filters,type,pageSize=50,fromIndex=0,sort_term="primary_name.label",sort_order="asc") {
    /* Helper function that turns a queryString and a filter array into a query */

    var query = {   query: {
                        bool: {
                            must: type !== 'all' ? [ { term: { display_type: type } } ] : [],
                            must_not: [ { terms: { "classified_as.label": [ 'level 1', 'level 2', 'level 3 - perspective' ] } } ]
                        }
                    },
                    sort: [ { [sort_term]: sort_order } ], 
                    size: pageSize,
                    from: fromIndex,
                    aggs: searchFacetAggregations(filters),
                    post_filter: searchFilterToElasticFilterSyntax(filters)
                }

    if (type === 'TimelineEvent') {
        query.sort = [ {'timespan.date_first': 'asc'}, {'timespan.date_last': 'asc'}, { 'primary_name.label': 'asc' }, {'primary_name.label': {'missing': '_last'} } ]
    }
    
    if ( filters.length == 0 && ( queryString === null || queryString === "" ) ) { return query } 
    
    if ( queryString !== undefined && queryString !== "" ) {
        query.query.bool.must.push( { "multi_match": { "query": queryString, "analyzer": "name_phonetic", "fields": [ "primary_name.label.phonetic" ] } } )
        query.query.bool.must.push( { "multi_match": { "query": queryString, "analyzer": "simple", "fuzziness": 2, "fields": [ "primary_name.label.simple^10" ] } } )
    }

    // filteredQuery = searchFilterToElasticFilterSyntax(filters)

    for (const filter in filters) {
        switch (filter) {
            case 'related': 
                query.query.bool.must.push( { term: {"related.link": filters[filter] } } )
                break;
            case 'active_begin':
                let beginYear = filters[filter];
                query.aggs.artist_role.aggs.active.filter.range['active.date_years'].gte = beginYear;
                query.aggs.type_of_art.aggs.active.filter.range['active.date_years'].gte = beginYear;
                query.aggs.occupation.aggs.active.filter.range['active.date_years'].gte = beginYear;
                query.post_filter.range['active.date_years'].gte = beginYear;
                break;
            case 'active_end':
                let endYear = filters[filter];
                query.aggs.artist_role.aggs.active.filter.range['active.date_years'].lte = endYear;
                query.aggs.type_of_art.aggs.active.filter.range['active.date_years'].lte = endYear;
                query.aggs.occupation.aggs.active.filter.range['active.date_years'].lte = endYear;
                query.post_filter.range['active.date_years'].lte = endYear;
                break;
        }

    }

    return query

}

function relationshipsContextToQuery(ident) {
    /* Helper function that turns a queryString and a filter array into a query */

    var query = {"size":200,"query":{ bool: { must: [{"terms":{"related.link":[ ident ] }}], must_not: [{ids: { values: [ ident ] } }] } } }

    return query
}

function documentsContextToQuery(ident, pageSize=200) {
    /* Helper function that turns a queryString and a filter array into a query */

    var query = { size: pageSize, 
                  query: { bool: { must: [
                              { terms: { 'related.link': [ ident ] } },
                              { terms: { 'display_type': [ 'Document' ] } }
                              ]
                          }
                        }
                }

    return query
}

function essaysHomeContextToQuery() {
    /* Helper function that turns a queryString and a filter array into a query */

    var query = { size: 20, 
                  query: { bool: { must: [
                              { terms: { 'display_type': [ 'Essay' ] } }
                              ]
                          }
                        }
                }

    return query
}

function essaysTabContextToQuery(ident) {
    /* Helper function that turns a queryString and a filter array into a query */

    var query = { size: 200, 
        query: { bool: { must: [
                    { terms: { 'related.link': [ ident ] } },
                    { terms: { 'display_type': [ 'Essay', '' ] } }
                    ]
                }
            }
    }

    return query
}

function inventoriesContextToQuery(ident) {
    /* Helper function that turns a queryString and a filter array into a query */

    var query = { size: 20, 
                  query: { bool: { must: [
                              { ids: { values: [ ident ?? '' ] } }
                              ]
                          }
                        }
                }

    return query

}

const timelineQuery = (context) => {

    /*
      Data handling:
        - All groups [n~=5] displayed at page load
        - Click of group:
          - Reveals all clusters
          - Reveals events in first cluster
 
    */

    let query = {
                    query: {
                        bool:{
                            must: [
                                {
                                    term: { display_type: 'TimelineEvent' }
                                }
                            ],
                            // must_not: [
                            //     {
                            //         term: { "classified_as.label": 'T3-major-events'}
                            //     }
                            // ]
                        }
                    },
                    size: 500,
                    sort: {
                        "timespan.date_first": 'asc'
                    }
    }

    return query
}

const textWrap = ((text, width, lineHtEm) => {
    text.each(function() {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = lineHtEm, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
        }
      }
    });
});

const truncate = (str, limit) => {
    return str.length > limit ? str.substring(0, (limit - 3)) + '...' : str;
}

const truncateWordBoundary = (str, limit) => {
    //trim the string to the maximum length
    var trimmedString = str.substr(0, limit);

    //re-trim if we are in the middle of a word
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
    return trimmedString + (trimmedString.length < str.length ? '...' : '');
}

const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

function eventDateFormat(date) {
    let dateFormatter = new Intl.DateTimeFormat('en', {dateStyle: 'medium', 'timeZone': 'UTC'});
    let formatted = dateFormatter.formatToParts(date).filter( datePart => datePart.type !== 'literal' ).reduce( (acc,cur) => { return cur.type==='day' ? `${cur.value} ` + acc : acc + ` ${cur.value}` }, '' );
    return formatted;
}

function eventNormalize(hit, locale='en', useSource=false) {
    /*
    Normalizes an elasticSearch `hit` object. Mostly that's unwrapping _source and fixing any misaligned date labels

    Change `locale` to get localized date handling on missing date labels (eg, Dec. / Déc. / Dez. ) 
    */
    var dateLabel = hit._source.timespan.date_label;
    let dateFirst = hit._source.timespan.date_first;
    let dateLast = hit._source.timespan.date_last;

    if ( dateLabel !== undefined && dateLabel !== '' ) {
      // We have a date-driven date label so do nothing
    } else if ( dateFirst == dateLast ) {
      let date = new Date(dateFirst * 1000);
      dateLabel = eventDateFormat(date);
    } else if (dateFirst < dateLast || dateFirst > dateLast ) {
      let firstDate = new Date(dateFirst * 1000);
      let secondDate = new Date(dateLast * 1000);
      dateLabel = `${eventDateFormat(firstDate)} to ${eventDateFormat(secondDate)}`;
    } else if (dateFirst !== undefined) {
      let firstDate = new Date(dateFirst * 1000);
      dateLabel = eventDateFormat(firstDate);
    }

    let related = hit._source.related.map( (item) => {  return { ...item, link: item.link.replace('http://www.collectionbrowse.org','')} } ) ?? [];
    let returnVal = { 
        _id: hit._id,
        date_label: dateLabel,
        date_first: dateFirst,
        date_last: dateLast, 
        description: parse(DOMPurify.sanitize(hit._source?.description?.html ?? '')) , 
        primary_name: {label: hit._source?.primary_name?.label ?? '' }, 
        part: hit._source.part ?? [],
        related: related
    };
    if (useSource) {
        returnVal['_source'] = hit._source;
    }

    return returnVal;
}

const sizeResettingErrorHandler = (fallbackSizeParam="full",errorImageSrcSet='/assets/images/image-404-242.png 1x') => {
    // Returns an error handler that attempts to reset the IIIF size parameter of the image to "full" and re-load, setting to errorImageSrcSet in case of persistent error.

    return (event) => {
        // If we hit an error, try dropping out of density-sensitive display and just try to show the image at our 1x density.
        // NB: This currently assumes an 2.1 endpoint

        // ... but do it under cover of night a bit
        event.target.classList.add('d-none')

        let currentImgUrl = new URL(event.target.currentSrc); 
        var urlParams = currentImgUrl.pathname.split('/')
        let sizeParam = urlParams.slice(-3,-2).pop()
        let minSizeParam = fallbackSizeParam

        switch (sizeParam) {
          case minSizeParam:
            // But if we're already at zero density, just set an error
            event.target.srcset =  errorImageSrcSet
            break;
          default:
            urlParams.splice(-3,1,minSizeParam)
            event.target.srcset = `${currentImgUrl.href.replace(currentImgUrl.pathname,urlParams.join('/'))} 1x`  
        }

        event.target.onload = (event) => {
          event.target.classList.remove('d-none')    
        }

    }

}

//  ref is the reference to the element whose height and with is required
//  const divRef = useRef(null);
//  const [ width, height ] = useDimension(divRef);
//  <div ref={divRef}>
const useDimension = (ref) => {
    const [dimensions, setDimensions] = useState([0, 0]);
    const resizeObserverRef = useRef(null);

    useEffect(() => {
      resizeObserverRef.current = new ResizeObserver((entries = []) => {
        entries.forEach((entry) => {
          const { width, height } = entry.contentRect;
          // console.log('useD: w = ' + width);
          setDimensions([width, height]);
        });
      });
      if (ref.current) resizeObserverRef.current.observe(ref.current);
      return () => {
        if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
      };
    }, [ref]);
    return dimensions;
}

function isLocalUrl(stringurl) {
    const url = new URL(stringurl, window.location); // new URL() will fail on at least Safari if there is no hostname
    // is local iff there's no hostname, or the hostname contains 'jdcrp.org'
    const isLocal = url.hostname === window.location.hostname // (!url.hostname || url.hostname.indexOf('jdcrp.org') >= 0 || url.hostname.indexOf('jdcrp-demo.org') >= 0);
    // console.log('local? ' + isLocal);
    return isLocal;
}

const usePreloadImages = (imageSrcs) => {
  useEffect(() => {
    const randomStr = Math.random().toString(32).slice(2) + Date.now();
    window.usePreloadImagesData = window.usePreloadImagesData ?? {};
    window.usePreloadImagesData[randomStr] = [];
    for (const src of imageSrcs) {
      // preload the image
      console.log('usePreloadImages: preloading '+src);
      const img = new Image();
      img.src = src;
      // keep a reference to the image
      window.usePreloadImagesData[randomStr].push(img); 
    }
    return () => {
      delete window.usePreloadImagesData?.[randomStr];
    };
  }, [ imageSrcs ]);
};

export {getImageAltText, getFallbackImageUrl, FALLBACK_IMAGE_SIZES, sizeResettingErrorHandler, stateToParams, paramsToState, useElasticSearch, inventoriesContextToQuery, dateRangeBucketFormatter, dateRangeInputFormatter, essaysHomeContextToQuery, essaysTabContextToQuery, searchContextToQuery, relationshipsContextToQuery, documentsContextToQuery, timelineQuery, textWrap, truncate, truncateWordBoundary, yearsToDecades, decadeToYears, useWindowSize, eventNormalize, useDimension, isLocalUrl, usePreloadImages}
